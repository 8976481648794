






import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'LeadplusGroup',
})
export default class extends Vue {
    $refs!: any

    get formId () {
        return this.$route.params.formId
    }

    mounted () {
        const loader = document.getElementById('first-loader')
        if (loader) loader.style.display = 'none'

        this.loadForm(window, document, 'script', `${process.env.VUE_APP_LEADPLUS_OLD_API || ''}/render-without-groups/app.js`, 'rf')
        window.rf(this.formId)
    }

    loadForm (i: any, s: any, o: any, g: any, r: any, a?: any, m?: any) {
        const newDate: any = new Date()
        // eslint-disable-next-line prefer-rest-params
        i['LeadplusObject'] = r; if (i[r]) return; i[r] = function () { if (!i[r].q)i[r].q = []; i[r].q.push(arguments) }; i[r].l = 1 * newDate; a = s.createElement(o); m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
    }
}
